<template>
  <v-sheet>
    <div
      v-for="(item, index) in items"
      :key="item.name + index"
      class="wsRoundedHalf overflow-hidden mb-5"
      :style="`background: ${wsLIGHTCARD}; max-height: 240px;`"
    >
      <div
        class="d-md-flex d-lg-flex d-xl-flex d-xxl-flex align-shrink justify-start mb-4 d-sm-block"
        style="width: 100%"
      >
        <div :style="displayShow('sm') ? 'width: 100%;' : 'width: 400px; height: 240px'">
          <img
            :style="displayShow('sm') ? 'width: 100%; border-top-right-radius: 16px; border-bottom-right-radius: 16px' : 'width: 100%; height: 100%; object-fit: cover'"
            :src="item.image && item.image.trim() !== '' ? item.image : require(`@/modules/webinars/assets/no_student_webinar.png`)"
            alt="webinar image"
          >
        </div>

        <div class="pa-4" style="overflow: hidden; width: 100%">

          <h3 class="mb-1 " :style="`color: ${wsACCENT}`">{{ item.name }}</h3>
          <h5 class="mb-1 font-weight-regular" :style="`color: ${wsACCENT}`">Початок вебінару: {{ $time(item.date_start).regularTime() }}</h5>

          <ws-timer
              v-if="item.time_now && (($time(item.date_start).phpTimestamp() - $time().phpTimestamp()) > (item.time_before*60))"
              #default="{time}"
              start-on-mount
              :seconds="$time(item.date_start).phpTimestamp() - $time().phpTimestamp() - (item.time_before*60)"
          >
            <h5 class="font-weight-regular" :style="`color: ${wsACCENT}`">
              Доступ до кімнати через: <span style="font-weight: 600">{{ time }}</span>
            </h5>

          </ws-timer>
          <div class="d-flex align-center mt-6">
            <ws-button
                v-if="($time(item.date_start).phpTimestamp() - $time().phpTimestamp()) < (item.time_before*60)"
                class="mr-2"
                :label="$t('webinar.student.join')"
                @click="$emit('open-webinar', item)"
            />

            <ws-button
                :label="$t('Details')"
                icon="mdi-chevron-right"
                :outlined="($time(item.date_start).phpTimestamp() - $time().phpTimestamp()) < (item.time_before*60)"
                :color="($time(item.date_start).phpTimestamp() - $time().phpTimestamp()) < (item.time_before*60) ? wsACCENT : wsATTENTION"
                :to="businessDashLink(`webinars_student/${item.uuid}`)"
                min-width="100"
            />
          </div>

        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "wsWebinarItemsList",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
