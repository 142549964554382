<template>
  <div
    class="text-center"
    :style="`padding-top: ${top}px`"
    style="max-width: 530px; overflow: hidden; margin: auto"
  >
    <img
      v-if="image"
      :src="require(`@/modules/webinars/assets/${image}`)"
      :style="`height: ${imageHeight}px`"
      alt="main mage"
      class="mb-2"
    >
    <v-icon v-else size="84" :color="wsACCENT">
      {{ icon }}
    </v-icon>
    <h3 class="mb-2" >{{ title }}</h3>
    <p style="font-size: 13px; font-weight: 400" class="mb-6">{{ subtitle }}</p>
    <ws-button
      v-if="action"
      outlined
      :left-icon="buttonIcon"
      :label="action"
      @click="$emit('action')"
    />
  </div>
</template>

<script>
export default {
  name: "wsNoContentInfo.vue",
  props: {
    top: {
      type: Number,
      default: 200
    },
    image: {
      type: String,
      default: ''
    },
    imageHeight: {
      type: Number,
      default: 300
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
}
</script>



<style scoped>

</style>
