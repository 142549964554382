<template>
  <dash-page-new
    :root="$t('webinar.main.header')"
    :title="$t('webinar.main.header')"
    :loading="loading"
  >
    <template #header_action>
      <div class="d-flex align-center justify-start" style="cursor: pointer">
        <h5 class="mr-2 text-decoration-underline" :style="`color: ${wsACCENT}`">westudy.ua/elvis</h5>
        <v-icon :color="wsACCENT">mdi-open-in-new</v-icon>
      </div>

    </template>

    <template #default>
      <!-- Tab Header -->
      <ws-navigation-header
        :items="navigationSelect"
        v-model="navigation"
        class="mt-4"
      />

      <!-- Tab Content -->
      <v-sheet
        class="flex-grow-1"
      >
        <ws-navigation
          :items="navigationSelect"
          v-model="navigation"
          hide-header
        >
          <template #item.student-webinars>
            <!--  NO STUDENT WEBINARS    -->
            <v-sheet v-if="!studentWebinarsList.length">
              <ws-no-content-info
                :top="100"
                :title="$t('webinar.student.no_student_webinars_title')"
                :subtitle="$t('webinar.student.no_student_webinars_subtitle')"
                :action="$t('webinar.student.choose_webinar')"
                buttonIcon="mdi-school"
                image="no_student_webinar.png"
                @action="openOtherWebinarsTab"
              />
            </v-sheet>
            <!--  Student Webinars list    -->
            <ws-webinar-items-list v-else :items="studentWebinarsList" @open-webinar="openWebinarRoom"/>
          </template>

          <template #item.other-webinars>
            <!--  NO OTHER WEBINARS    -->
            <v-sheet v-if="!otherWebinarsList.length">
              <ws-no-content-info
                :top="300"
                :title="$t('webinar.student.no_other_webinars')"
                icon="mdi-laptop-account"
              />
            </v-sheet>

            <!--  Other Webinars list    -->
            <ws-webinar-items-list v-else :items="otherWebinarsList" @open-webinar="openWebinarRoom"/>

          </template>
        </ws-navigation>
      </v-sheet>
    </template>

    <template #dialog>
      <!-- Student Webinar room   -->

      <student-webinar-room
        v-if="displayDialog"
        v-model="displayDialog"
        :webinar="currentWebinar"
      />

    </template>

  </dash-page-new>
</template>

<script>
import StudentWebinarRoom from "@modules/webinars/components/student/webinar-room/StudentWebinarRoom.vue";
import WsNoContentInfo from "@/modules/webinars/components/UI/wsNoContentInfo.vue"
import {mapActions} from "vuex";
import wsWebinarItemsList from "@/modules/webinars/components/student/UI/wsWebinarItemsList.vue"

export default {
  name: "StudentWebinars",
  components: {wsWebinarItemsList, WsNoContentInfo, StudentWebinarRoom },
  data() {
    return {
      loading: false,
      currentWebinar: {},
      displayDialog: false,
      navigation: 'student-webinars',
      studentWebinarsList: [],
      otherWebinarsList: []
    }
  },
  computed: {
    navigationSelect() {
      return [
        { text : this.$t('webinar.student.student_webinars') , value : 'student-webinars' },
        { text : this.$t('webinar.student.other_webinars') , value : 'other-webinars' },
      ]
    },
  },
  methods: {
    ...mapActions('webinars', ['GET_STUDENT_WEBINARS', 'GET_OTHER_WEBINARS']),

    openOtherWebinarsTab() {
      this.navigation = 'other-webinars';
    },

    openWebinarRoom(item) {
      this.currentWebinar = item
      this.displayDialog = true
    },

    async getOtherWebinars() {
      this.loading = true;
      const result = await this.GET_OTHER_WEBINARS();
      this.loading = false;

      if (!result) {
        this.ERROR();
        return
      }

      this.otherWebinarsList = result.items;
      const webinars = [...this.otherWebinarsList]
      this.otherWebinarsList = webinars.map(el => {
        const { date, time } = this.parseDate(el.date_start)
        return {
          ...el,
          date,
          time
        }
      })
    },

    // Technical

    parseDate(fullDate) {
      const date = fullDate.split(' ')[0];
      const time = fullDate.split(' ')[1];
      return { date, time }
    },

    async initPage() {
      this.loading = true;
      const result = await this.GET_STUDENT_WEBINARS();
      this.loading = false;
      if (!result) {
        this.ERROR();
        return
      }
      this.studentWebinarsList = result.items;
      const webinars = [...this.studentWebinarsList];
      this.studentWebinarsList = webinars.map(el => {
        const { date, time } = this.parseDate(el.date_start)
        return {
          ...el,
          date,
          time
        }
      })
    }
  },
  watch:{
    navigation(value) {
      value === 'other-webinars' ? this.getOtherWebinars() : this.initPage()
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>


</style>
